import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import { InputBase, type InputBaseProps, styled } from "@mui/material"

const UnstyledInput = styled(InputBase)({
	'& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
		appearance: "none",
		margin: 0,
	},
})

const InputNumberContainer = styled("div")(({ theme }) => ({
	border: `1px solid ${theme.palette.grey[400]}`,
	borderRadius: "4px",
}))

const InputNumberSpinnerContainer = styled("div")(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-around",
}))

const InputNumberSpinner = styled("div")(({ theme }) => ({
	display: "inline-flex",
	"&:hover": {
		cursor: "pointer",
		background: theme.palette.grey[200],
	},
	"& svg": {
		width: "16px",
		height: "16px",
	},
}))

export const InputNumber = (props: InputBaseProps & { min: number; max: number; onChange: (v: number) => void }) => {
	const { onChange, value, ...rest } = props

	const min = props.min ? Number(props.min) : undefined
	const max = props.max ? Number(props.max) : undefined

	const handleChange = (v: number) => {
		if (onChange) {
			if (
				Number.isNaN(v) ||
				(min && max && v >= min && v <= max) ||
				(min && !max && v >= min) ||
				(!min && max && v <= max) ||
				!(min || max)
			)
				onChange(v)
		}
	}
	return (
		<InputNumberContainer className="flex w-20 rounded-sm border-b-2">
			<div className="px-2 py-1">
				<UnstyledInput
					type="number"
					onChange={(e) => handleChange(Number.parseInt(e.target.value, 10))}
					value={value}
					{...rest}
				/>
			</div>
			<InputNumberSpinnerContainer>
				<InputNumberSpinner onClick={() => handleChange(Number(value) + 1)}>
					<ArrowDropUpIcon fontSize="small" />
				</InputNumberSpinner>
				<InputNumberSpinner onClick={() => handleChange(Number(value) - 1)}>
					<ArrowDropDownIcon fontSize="small" />
				</InputNumberSpinner>
			</InputNumberSpinnerContainer>
		</InputNumberContainer>
	)
}
