import CloseIcon from "@mui/icons-material/Close"
import {
	CircularProgress,
	type DialogProps,
	Dialog as MuiDialog,
	DialogActions as MuiDialogActions,
	DialogContent as MuiDialogContent,
	DialogTitle as MuiDialogTitle,
	styled,
} from "@mui/material"
import type { ReactNode, SyntheticEvent } from "react"

export const Dialog = styled(MuiDialog)(() => ({}))

export const StyledDialogTitle = styled(MuiDialogTitle)(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
}))

export const DialogTitle = ({
	children,
	onClose,
	...props
}: { children: ReactNode; onClose?: (e: SyntheticEvent) => void } & Partial<typeof StyledDialogTitle>) => {
	return (
		<StyledDialogTitle {...props}>
			{children}
			{onClose && (
				<button className="inline ml-4" type="button" onClick={onClose}>
					<CloseIcon />
				</button>
			)}
		</StyledDialogTitle>
	)
}

export const DialogLoading = styled("div")(() => ({
	position: "absolute",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	backgroundColor: "rgba(255, 255, 255, 0.5)",
}))

export const DialogLoadingText = styled("p")(({ theme }) => ({
	fontSize: theme.typography.fontSize * 2,
	fontWeight: "bold",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
}))

export const DialogContent = MuiDialogContent

export const DialogActions = MuiDialogActions

export interface DialogComponentProps {
	title: string
	children: ReactNode
	open: boolean
	loading?: boolean
	loadingText?: string
	onClose: () => void
}

export const DialogComponent = ({
	title,
	children,
	open,
	loading = false,
	onClose,
	...rest
}: DialogComponentProps & DialogProps) => {
	return (
		<Dialog disableScrollLock open={open} onClose={onClose} {...rest}>
			{loading && (
				<DialogLoading>
					<DialogLoadingText>
						<CircularProgress />
					</DialogLoadingText>
				</DialogLoading>
			)}
			<DialogTitle onClose={onClose}>{title}</DialogTitle>
			{children}
		</Dialog>
	)
}
