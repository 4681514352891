import { Input as MuiInput } from "@mui/base/Input"
import { styled } from "@mui/material"
import type { InputHTMLAttributes, ReactNode } from "react"

interface InputBaseVariants extends InputHTMLAttributes<HTMLInputElement> {
	error: boolean
	leftIcon?: ReactNode
	disabled: boolean
}

export const InputBaseContainer = styled("div")<InputBaseVariants>(({ theme }) => ({
	width: "100%",
	position: "relative",
	"& input": {
		color: theme.palette.grey[800],
	},
	"& > .InputBaseContainerInner-container:focus-within": {
		borderBottomColor: theme.palette.primary.main,
	},
	"& svg": {
		fill: theme.palette.divider,
	},
	"& > .InputBaseContainerInner-container:focus-within svg": {
		fill: theme.palette.primary.main,
	},
	variants: [
		{ props: { disabled: true }, style: { pointerEvents: "none", opacity: "0.5", background: "unset" } },
		{
			props: { error: true },
			style: {
				"& > .InputBaseContainerInner-container": {
					borderBottomColor: theme.palette.error.main,
				},
				"& svg": {
					fill: `${theme.palette.error.main} !important`,
				},
				"& > .InputBaseContainerInner-leftIcon": {
					color: theme.palette.error.main,
					fill: theme.palette.error.main,
				},
				"& > .InputBaseContainerInner-container:focus-within": {
					borderBottomColor: theme.palette.error.main,
				},
				"& > .InputBaseContainerInner-container input::placeholder": {
					color: theme.palette.error.main,
				},
			},
		},
	],
}))

export const InputBaseContainerInner = styled("div")(({ theme }) => ({
	display: "flex",
	position: "absolute",
	gap: theme.spacing(1),
	left: 0,
	right: 0,
	bottom: 0,
	borderBottom: `2px solid ${theme.palette.divider}`,
}))

export const InputLeftIcon = styled("span")(() => ({
	display: "flex",
	alignItems: "center",
}))

export const InputBase = styled(MuiInput)(({ theme }) => ({
	flexGrow: 1,
	"& input": {
		height: "1.5rem",
		padding: 0,
		fontSize: theme.typography.fontSize * 1.25,
		border: "unset",
		outline: 0,
		width: "100%",
	},
}))

export const Input = ({ children, leftIcon, ...props }: InputBaseVariants) => {
	return (
		<InputBaseContainer {...props} className="InputBaseContainer-root">
			<InputBaseContainerInner className="InputBaseContainerInner-container">
				{/* {leftIcon && <span className="InputBaseContainerInner-leftIcon">{leftIcon}</span>} */}
				{leftIcon && <InputLeftIcon>{leftIcon}</InputLeftIcon>}
				<InputBase {...(props.disabled ? { disabled: props.disabled } : {})} />
			</InputBaseContainerInner>
		</InputBaseContainer>
	)
}
