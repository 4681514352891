import { styled } from "@mui/material"

interface KbdVariants {
	size?: "small"
}

export const Kbd = styled("kbd")<KbdVariants>(({ theme }) => ({
	backgroundColor: theme.palette.grey[200],
	borderRadius: theme.shape.borderRadius,
	border: `1px solid ${theme.palette.grey[300]}`,
	color: theme.palette.grey[800],
	display: "inline",
	fontWeight: 800,
	padding: `${theme.spacing(0.2)} ${theme.spacing(0.8)}`,
	whiteSpace: "nowrap",
	variants: [
		{
			props: { size: "small" },
			style: {
				fontSize: theme.typography.fontSize,
				padding: "2px 4px",
			},
		},
	],
}))

export const ShortcutSeparator = styled("span")<KbdVariants>(({ theme }) => ({
	variants: [
		{
			props: { size: "small" },
			style: {
				fontSize: theme.typography.fontSize,
				padding: "2px 4px",
			},
		},
	],
}))

export const KbdShortcut = ({ keys, size = "small" }: { keys: string[]; size?: KbdVariants["size"] }) => {
	return (
		<span>
			{keys.map((key, index) => (
				<span key={key}>
					<Kbd size={size}>{key}</Kbd>
					{index < keys.length - 1 && <ShortcutSeparator size={size}> + </ShortcutSeparator>}
				</span>
			))}
		</span>
	)
}
