import { isEqual } from "es-toolkit"
import { useFormState } from "react-final-form"

export enum FormChangeState {
	DIRTY,
	ERROR,
	NOT_DIRTY,
	SAVED,
}

export function useFormChangeState(enable = true): FormChangeState {
	if (!enable) return FormChangeState.NOT_DIRTY
	try {
		const formState = useFormState()

		if (formState.modifiedSinceLastSubmit) return FormChangeState.DIRTY
		if (formState.submitSucceeded) return FormChangeState.SAVED
		// dirty is actually true if the form was touched, even if the values are the same as initialValues

		const isDirty = !isEqual(formState.initialValues, formState.values)
		if (formState.pristine) return FormChangeState.NOT_DIRTY
		if (formState.submitError) return FormChangeState.ERROR
		if (isDirty) return FormChangeState.DIRTY
	} catch {
		// Ignore the error when the component is not wrapped into a `Form`.
	}
	return FormChangeState.NOT_DIRTY
}
