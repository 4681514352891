import { Button as MuiButton } from "@mui/base/Button"
import { alpha, styled } from "@mui/material"
import type { ButtonHTMLAttributes, ReactNode } from "react"

interface ButtonBaseVariants extends ButtonHTMLAttributes<HTMLButtonElement> {
	rightIcon?: ReactNode
	leftIcon?: ReactNode
	variant?: "contained" | "outlined" | "dashed" | "text"
	color?: "primary" | "warning" | "danger" | "secondary" | "grey" | "success"
	fullWidth?: boolean
}

export const ButtonIconContainer = styled("span")(() => ({
	display: "inline-flex",
}))

export const ButtonBase = styled(MuiButton, {
	shouldForwardProp: (prop) => prop !== "fullWidth",
})<ButtonBaseVariants>(({ theme }) => ({
	fontSize: "0.875rem",
	width: "max-content",
	lineHeight: "2rem",
	height: "2rem",
	display: "flex",
	cursor: "pointer",
	justifyContent: "center",
	whiteSpace: "nowrap",
	alignItems: "center",
	borderRadius: theme.shape.borderRadius,
	border: "unset",
	paddingLeft: theme.spacing(1),
	paddingRight: theme.spacing(1),
	"& .MuiSvgIcon-root": {
		display: "flow",
	},
	gap: theme.spacing(0.5),
	variants: [
		{
			props: { disabled: true },
			style: { pointerEvents: "none", opacity: 0.5 },
		},
		{
			props: { fullWidth: true },
			style: { width: "100%" },
		},
		{
			props: { variant: "dashed", color: "primary" },
			style: {
				backgroundColor: "white",
				borderColor: theme.palette.primary.main,
				borderStyle: "dashed",
				borderWidth: 2,
				fontWeight: 600,
				color: theme.palette.primary.main,
				transition: theme.transitions.create(["background-color", "transform"], {
					duration: theme.transitions.duration.short,
				}),
				"&:hover": {
					backgroundColor: theme.palette.primary.light,
				},
			},
		},
		{
			props: { variant: "contained", color: "grey" },
			style: {
				backgroundColor: theme.palette.grey[200],
				color: theme.palette.grey[800],
				transition: theme.transitions.create(["background-color", "transform"], {
					duration: theme.transitions.duration.short,
				}),
				"&:hover": {
					backgroundColor: theme.palette.grey[300],
				},
			},
		},
		{
			props: { variant: "contained", color: "primary" },
			style: {
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
				transition: theme.transitions.create(["background-color", "transform"], {
					duration: theme.transitions.duration.short,
				}),
				"&:hover": {
					backgroundColor: theme.palette.primary.light,
				},
			},
		},
		{
			props: { variant: "contained", color: "secondary" },
			style: {
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.secondary.contrastText,
				"&:hover": {
					backgroundColor: theme.palette.secondary.light,
				},
			},
		},
		{
			props: { variant: "contained", color: "success" },
			style: {
				backgroundColor: theme.palette.success.main,
				color: theme.palette.success.contrastText,
				transition: theme.transitions.create(["background-color", "transform"], {
					duration: theme.transitions.duration.short,
				}),
				"&:hover": {
					backgroundColor: theme.palette.success.light,
				},
			},
		},
		{
			props: { variant: "contained", color: "warning" },
			style: {
				backgroundColor: theme.palette.warning.main,
				color: theme.palette.warning.contrastText,
				transition: theme.transitions.create(["background-color", "transform"], {
					duration: theme.transitions.duration.short,
				}),
				"&:hover": {
					backgroundColor: theme.palette.warning.light,
				},
			},
		},
		{
			props: { variant: "contained", color: "danger" },
			style: {
				backgroundColor: theme.palette.error.main,
				color: theme.palette.error.contrastText,
				transition: theme.transitions.create(["background-color", "transform"], {
					duration: theme.transitions.duration.short,
				}),
				"&:hover": {
					backgroundColor: theme.palette.error.light,
				},
			},
		},
		{
			props: { variant: "outlined", color: "secondary" },
			style: {
				backgroundColor: "transparent",
				color: theme.palette.secondary.dark,
				border: `1px solid ${theme.palette.secondary.main}`,
				"&:hover": {
					backgroundColor: alpha(theme.palette.secondary.main, 0.5),
				},
			},
		},
		{
			props: { variant: "text", color: "primary" },
			style: {
				color: theme.palette.primary.main,
				transition: theme.transitions.create(["background-color", "color", "transform"], {
					duration: theme.transitions.duration.short,
				}),
				"&:hover": {
					backgroundColor: theme.palette.blue[50],
				},
			},
		},
	],
}))

export const Button = ({ children, leftIcon, rightIcon, ...props }: ButtonBaseVariants) => {
	return (
		<ButtonBase {...props}>
			{leftIcon && <ButtonIconContainer className="left-icon">{leftIcon}</ButtonIconContainer>}
			{children}
			{rightIcon && <ButtonIconContainer className="right-icon">{rightIcon}</ButtonIconContainer>}
		</ButtonBase>
	)
}
