import { Tab as BaseTab } from "@mui/base/Tab"
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel"
import { Tabs as BaseTabs } from "@mui/base/Tabs"
import { TabsList as BaseTabsList } from "@mui/base/TabsList"
import { styled } from "@mui/material"

export const Tabs = styled(BaseTabs)(() => ({}))

export const Tab = styled(BaseTab)(({ theme }) => ({
	color: theme.palette.grey[600],
	background: "transparent",
	fontWeight: 500,
	cursor: "pointer",
	fontSize: theme.typography.fontSize * 1.25,
	width: "100%",
	lineHeight: 1.5,
	padding: "8px 12px",
	borderColor: theme.palette.grey[300],
	borderWidth: "2px",
	borderStyle: "solid",
	borderRadius: "9999px",
	display: "flex",
	justifyContent: "center",

	"&:hover": {
		backgroundColor: theme.palette.grey[100],
	},

	"&:focus": {
		outline: "none",
	},

	// [`&.${tabClasses.selected}`]: {
	// 	background: theme.palette.grey[100],
	// },
	"&.base--selected": {
		background: theme.palette.grey[100],
	},

	// [`&.${buttonClasses.disabled}`]: {
	// 	opacity: 0.5,
	// 	cursor: "not-allowed",
	// },
	"&.base--disabled": {
		opacity: 0.5,
		cursor: "not-allowed",
	},
}))

export const TabPanel = styled(BaseTabPanel)(({ theme }) => ({
	width: "100%",
	fontSize: theme.typography.fontSize * 1.25,
}))

export const TabsList = styled(BaseTabsList)(() => ({
	minWidth: "400px",
	backgroundColor: "transparent",
	marginBottom: "16px",
	display: "flex",
	gap: "16px",
	alignItems: "center",
	justifyContent: "center",
	alignContent: "space-between",
}))
