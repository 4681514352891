import { useIsAdmin, useIsGranted, useIsGranted_LEGACY } from "@utils/auth"
import { useCallback, useMemo } from "react"
import { PermissionTarget } from "../config/permissionTargets"
import type { BrandOption } from "../pages/shared/components/select-brand"

export enum SecurityMode {
	LEGACY,
	NEW,
}

export function useSecurity() {
	const isGrantedNew = useIsGranted()
	const isGranted = useIsGranted_LEGACY()
	const isAdminFromHook = useIsAdmin()

	const isComputedAdmin = useMemo(() => isAdminFromHook ?? false, [isAdminFromHook])
	const isAdmin = useCallback(() => isComputedAdmin, [isComputedAdmin])
	const canDeleteEntities = useCallback(() => isGrantedNew(PermissionTarget.ENTITY_DELETION), [isGrantedNew])
	const canDeleteSeries = useCallback(
		() => canDeleteEntities() && isGrantedNew(PermissionTarget.SERIES),
		[isGrantedNew, canDeleteEntities],
	)
	const canEditSeoData = useCallback(
		() => isGrantedNew(PermissionTarget.ENTITY_SEO_DATA) || isGranted("seo.*"),
		[isGranted, isGrantedNew],
	)

	const canEditEntityOnBrand = useCallback(
		({
			brand,
			entityType,
		}: {
			brand?: BrandOption | null
			entityType: "Person" | "Movie" | "Series" | "SeriesSeason" | "SeriesEpisode"
		}) => {
			if (!brand) return false // event admins or seo managers need to specify a brand at this level
			if (isGrantedNew(PermissionTarget.ENTITY_SEO_DATA) || isGranted("seo.*")) return true
			if (isGrantedNew(PermissionTarget.ENTITY_BRAND_DATA)) return true
			if (entityType === "Person") return isGranted(`person.brand.${brand.ref}`)
			if (entityType === "Movie") return isGranted(`movie.brand.${brand.ref}`)
			if (entityType === "Series") return isGranted(`series.brand.${brand.ref}`)
			if (entityType === "SeriesSeason") return isGranted(`series.brand.${brand.ref}`)
			if (entityType === "SeriesEpisode") return isGranted(`series.brand.${brand.ref}`)
			return false
		},
		[isGranted, isGrantedNew],
	)
	const canEditSeries = useCallback(
		() => isGrantedNew(PermissionTarget.SERIES) || isGranted("series.*"),
		[isGranted, isGrantedNew],
	)
	const canEditPersons = useCallback(
		() => isGranted(`${PermissionTarget.PERSON}.*`) || isGrantedNew(PermissionTarget.PERSON),
		[isGranted, isGrantedNew],
	)

	const canEditMovies = useCallback(
		() => isGrantedNew(PermissionTarget.MOVIE) || isGranted("movie.*"),
		[isGranted, isGrantedNew],
	)

	return {
		isAdmin,

		isGrantedNew,
		isGranted,

		canEditSeoData,

		canEditEntityOnBrand,
		canDeleteEntities,

		canEditMovies,
		canDeleteMovies: () => canDeleteEntities() && isGrantedNew(PermissionTarget.MOVIE),
		canEditMovieOnBrand: (brand: BrandOption | null | undefined) => canEditEntityOnBrand({ brand, entityType: "Movie" }),

		canEditLocalCredit: ({ scope, ref }: { scope: string; ref?: string }) => {
			return (
				isGranted(`${scope}.locale.*`) ||
				canEditPersons() ||
				isGrantedNew(PermissionTarget.ENTITY_LOCALE_DATA) ||
				(ref ? isGranted(`${scope}.locale.${ref}`) : false)
			)
		},

		canDeletePersons: () => canDeleteEntities() && isGrantedNew(PermissionTarget.PERSON),
		canEditPersons,
		canEditPersonOnBrand: (brand: BrandOption | null | undefined) =>
			canEditEntityOnBrand({ brand, entityType: "Person" }),

		canEditSeries,
		canEditSeriesOnBrand: (brand: BrandOption | null | undefined) =>
			canEditEntityOnBrand({ brand, entityType: "Series" }),
		canDeleteSeries,

		canEditSeriesSeasons: canEditSeries,
		canDeleteSeriesSeason: () => canDeleteSeries() || isGrantedNew(PermissionTarget.SERIES_SEASON_DELETE),
		canEditSeriesSeasonOnBrand: (brand: BrandOption | null | undefined) =>
			canEditEntityOnBrand({ brand, entityType: "SeriesSeason" }),

		canEditSeriesEpisodes: canEditSeries,
		canDeleteSeriesEpidodes: () => canDeleteSeries() || isGrantedNew(PermissionTarget.SERIES_EPISODE_DELETE),
		canEditSeriesEpisodeOnBrand: (brand: BrandOption | null | undefined) =>
			canEditEntityOnBrand({ brand, entityType: "SeriesEpisode" }),

		canEditMovieRulingDataOnBrand: (brand: BrandOption | null | undefined) =>
			canEditEntityOnBrand({ brand, entityType: "Movie" }) && isGrantedNew(PermissionTarget.MOVIE_BRANDED_DATA_RULING),

		canEditSeriesRulingDataOnBrand: (brand: BrandOption | null | undefined) =>
			canEditEntityOnBrand({ brand, entityType: "Series" }) && isGrantedNew(PermissionTarget.SERIES_BRANDED_DATA_RULING),
	}
}
