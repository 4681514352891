import clsx from "clsx"
import { forwardRef, useCallback, useEffect, useRef, useState } from "react"

import { IconButton } from "@components/button"
import { Input } from "@components/input"
import { useHotkey } from "@hooks/use-hotkey"
import { BlockIcon } from "@icons/block-icon"
import { SaveIcon } from "@icons/save-icon"
import { Popper } from "@mui/material"
import type { InputHTMLAttributes, MutableRefObject, ReactNode } from "react"

export type ValueType = InputHTMLAttributes<HTMLInputElement>["value"]

export type ReorderButtonGroupProps = {
	disabled?: boolean
	variant?: "tooltip" | "default"
	className?: string
	inputMaxValue: number
	defaultInputValue: number
	popper?: boolean
	onReorder: (rank: number) => void
}

const PopperContainer = forwardRef(({ children, isTouch }: { children: ReactNode; isTouch: boolean }, ref) => (
	<Popper placement="right" open={isTouch} anchorEl={(ref as MutableRefObject<HTMLInputElement>).current ?? null}>
		<div className="p-2 bg-white drop-shadow-xl flex gap-2">{children}</div>
	</Popper>
))

const DefaultContainer = ({
	children,
	variant,
}: { children: ReactNode; variant: ReorderButtonGroupProps["variant"] }) => (
	<div
		className={clsx("flex gap-2", {
			...(variant === "tooltip"
				? {
						"absolute z-[1] -right-20 -top-2 p-2 bg-white drop-shadow-xl": true,
					}
				: {}),
		})}
	>
		{children}
	</div>
)

export function ReorderButtonGroup({
	disabled = false,
	inputMaxValue,
	defaultInputValue,
	className,
	variant = "tooltip",
	onReorder,
	popper = false,
}: ReorderButtonGroupProps) {
	const ref = useRef<HTMLInputElement>(null)
	const [value, setValue] = useState<number>(defaultInputValue)
	const [isTouch, setIsTouch] = useState<boolean>(false)

	useEffect(() => {
		setValue(defaultInputValue)
	}, [defaultInputValue])

	const onSubmit = useCallback(() => {
		setIsTouch(false)
		if (typeof value === "number") onReorder(value)
	}, [value, onReorder])

	const inputProps = {
		min: 1,
		max: inputMaxValue,
	}

	const Container = popper
		? (props: { children: ReactNode }) => <PopperContainer {...props} isTouch={isTouch} ref={ref} />
		: (props: { children: ReactNode }) => <DefaultContainer {...props} variant={variant} />

	useHotkey("Escape", () => {
		setIsTouch(false)
	})
	return (
		<div
			onFocus={() => {
				ref.current?.focus()
			}}
			className={clsx(
				"flex gap-2",
				{
					relative: variant === "tooltip",
				},
				className,
			)}
		>
			<Input
				ref={ref}
				{...inputProps}
				disabled={disabled}
				className="w-10 text-center"
				type="number"
				value={value}
				onChange={(e) => {
					setIsTouch(true)
					const parsed = Number.parseInt(e.target.value, 10)
					if (Number.isNaN(parsed)) setValue(1)
					else {
						if (parsed < inputProps.min || parsed > inputProps.max) return
						setValue(parsed)
					}
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter") onSubmit()
				}}
			/>
			{isTouch && (
				<Container>
					<IconButton
						disabled={disabled}
						color="danger"
						title="Cancel"
						type="button"
						onMouseDown={() => {
							setValue(defaultInputValue)
							setIsTouch(false)
						}}
					>
						<BlockIcon />
					</IconButton>
					<IconButton disabled={disabled} color="success" title="Confirm" type="button" onMouseDown={onSubmit}>
						<SaveIcon />
					</IconButton>
				</Container>
			)}
		</div>
	)
}
