import { styled } from "@mui/material"

export const InfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	fontSize: theme.typography.fontSize * 1.25,
	flexDirection: "row",
	variants: [
		{
			props: { color: "grey" },
			style: {
				backgroundColor: theme.palette.grey[50],
				border: `1px solid ${theme.palette.grey[300]}`,
				borderRadius: theme.shape.borderRadius,
				padding: theme.spacing(1),
			},
		},
	],
}))

export const KeyboardInfoContainer = styled(InfoContainer)({
	justifyContent: "space-between",
})
