import { Menu as MuiMenu, MenuItem as MuiMenuItem, styled } from "@mui/material"

export const Menu = styled(MuiMenu)(() => ({}))

interface MenuItemVariants {
	hidden?: boolean
	spaceBetween?: boolean
}

export const MenuItem = styled(MuiMenuItem, {
	shouldForwardProp: (prop) => prop !== "spaceBetween",
})<MenuItemVariants>(({ theme }) => ({
	display: "flex",
	gap: theme.spacing(1),
	alignItems: "center",
	variants: [
		{
			props: { hidden: true },
			style: {
				display: "none",
			},
		},
		{
			props: { spaceBetween: true },
			style: {
				"& > :first-of-type": {
					display: "flex",
					gap: theme.spacing(1),
				},
				gap: "4rem",
				justifyContent: "space-between",
			},
		},
	],
}))
