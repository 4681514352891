import {
	type AutocompleteProps,
	type ChipTypeMap,
	Autocomplete as MuiAutocomplete,
	TextField,
	styled,
} from "@mui/material"
import type { ComponentProps, ElementType } from "react"

interface AutocompleteStyledProps {
	noBorder?: boolean
}

export const StyledAutocomplete = styled(MuiAutocomplete, {
	shouldForwardProp: (prop) => prop !== "noBorder",
})<AutocompleteStyledProps>(({ theme }) => ({
	"& .MuiOutlinedInput-root": {
		padding: "unset",
	},
	"& .MuiOutlinedInput-root .MuiAutocomplete-input": {
		paddingLeft: theme.spacing(0.5),
	},
	variants: [
		{
			props: { noBorder: true },
			style: {
				"& fieldset": {
					border: "none",
				},
			},
		},
	],
}))
export const StyledAutocompletePaper = styled("div", {
	shouldForwardProp: (prop) => prop !== "noBorder",
})(() => ({
	marginTop: 10,
	background: "white",
	borderRadius: "4px",
	minWidth: "400px",
	boxShadow: "0px 0px 10px 2px rgba(0,0,0,0.3)",
}))

export const StyledAutocompleteTextField = styled(TextField, {
	shouldForwardProp: (prop) => prop !== "noBorder",
})(() => ({
	"& .MuiInputBase-root": {
		padding: "unset",
	},
}))

export const Autocomplete = <
	Value,
	Multiple extends boolean | undefined = false,
	DisableClearable extends boolean | undefined = false,
	FreeSolo extends boolean = false,
	ChipComponent extends ElementType = ChipTypeMap["defaultComponent"],
>(
	props: Omit<
		AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
		"freeSolo" | "renderInput"
	> &
		AutocompleteStyledProps & { freeSolo?: boolean } & {
			renderInput?: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>["renderInput"]
		},
) => (
	<StyledAutocomplete
		clearOnBlur={false}
		clearOnEscape={false}
		{...(props as ComponentProps<typeof StyledAutocomplete>)}
		renderInput={(params) => <StyledAutocompleteTextField {...params} />}
		slots={{ paper: (params) => <StyledAutocompletePaper {...params} /> }}
	/>
)
