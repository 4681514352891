import { Menu } from "@allocine/ui-components"
import AppsOutageIcon from "@mui/icons-material/AppsOutage"
import DragHandleIcon from "@mui/icons-material/DragHandle"
import { Box, Skeleton, alpha, styled } from "@mui/material"
import {
	DataGridPremium,
	type DataGridPremiumProps,
	type GridLoadingOverlayProps,
	type GridRowClassNameParams,
	type GridValidRowModel,
} from "@mui/x-data-grid-premium"
import React, { useMemo, useState, type ReactNode, type SyntheticEvent } from "react"

import { CircularProgress } from "./CircularProgress"
import { LinearProgress } from "./LinearProgress"
import { TABLE_DEFAULT_ROW_HEADER_HEIGHT, TABLE_DEFAULT_ROW_HEIGHT } from "./Table.common"

export const DEFAULT_PAGE_OPTIONS = [10, 20, 30, 50, 100]

export const StyledTable = styled(DataGridPremium)(({ theme: { palette }, theme }) => ({
	border: "unset",
	borderRadius: 0,
	"& input": {
		fontSize: theme.typography.fontSize,
	},
	"@keyframes loading": {
		"0%": { backgroundColor: alpha(palette.grey[500], 0.1) },
		"100%": { backgroundColor: alpha(palette.grey[500], 0.1) },
	},
	"& .context-menu-active:hover": {
		background: palette.grey[100],
	},
	"& .context-menu-active": {
		pointerEvents: "none",
		background: palette.blue[50],
		opacity: 0.8,
	},
	"& .danger:hover": {
		background: palette.red[50],
	},
	"& .danger": {
		pointerEvents: "none",
		background: palette.red[50],
		opacity: 0.8,
	},
	"& .MuiDataGrid-row.MuiDataGrid-row--editing": {
		boxShadow: "unset",
	},
	"& .MuiDataGrid-row.MuiDataGrid-row--editing > div": {
		backgroundColor: `${palette.yellow[50]} !important`,
	},
	"& .MuiDataGrid-columnHeader": {
		borderTopWidth: "1px",
		borderColor: palette.grey[300],
		background: palette.grey[100],
	},
	"& .MuiDataGrid-columnHeaderTitle": {
		fontWeight: 600,
	},
	"& .MuiDataGrid-cell": {
		alignContent: "center",
		fontSize: theme.typography.fontSize,
	},
	"& .MuiDataGrid-rowReorderCell.MuiDataGrid-rowReorderCell--draggable:hover": {
		cursor: "grab",
	},
	"& .MuiDataGrid-cell > *": {
		verticalAlign: "middle",
	},
	"& .MuiDataGrid-overlayWrapper:has(.no-results), & .MuiDataGrid-main:not(:has(.MuiDataGrid-row)) .MuiDataGrid-overlayWrapper:has(.loader)":
		{
			minHeight: "3rem",
		},
	"& .MuiDataGrid-overlayWrapperInner:has(.loader)": {
		backgroundColor: alpha(palette.grey[500], 0),
		animationName: "loading",
		animationDuration: "2s",
		animationIterationCount: "infinite",
		animationTimingFunction: "linear",
	},
	"& .MuiDataGrid-toolbarContainer": {
		borderTopWidth: "1px",
		borderColor: palette.grey[300],
		padding: theme.spacing(1),
	},
})) as typeof DataGridPremium

// biome-ignore lint/suspicious/noExplicitAny: extracted from original lib
export type TableProps<R extends GridValidRowModel = any> = DataGridPremiumProps<R> & { pending?: boolean }

type ContextMenuHandler<T> = (e: SyntheticEvent, data: T, mousePosition: { mouseX: number; mouseY: number }) => void

const useRowContextMenu = <T extends WithId>(onContextMenu: ContextMenuHandler<T>) => {
	return (data: T[]) => (event: React.MouseEvent<HTMLDivElement>) => {
		event.preventDefault()
		const id = event.currentTarget.dataset.id as string
		const targetData = data.find((d) => d.id === id) as T
		onContextMenu(event, targetData, { mouseX: event.clientX, mouseY: event.clientY })
	}
}

export const GridToolbarContainer = styled("div")(() => ({
	display: "flex",
	flexWrap: "wrap",
	gap: ".5rem",
	alignItems: "center",
	padding: "0.5rem",
}))

interface WithId {
	id: string
}

export const Table = function Table<R extends GridValidRowModel & WithId = WithId>(
	props: TableProps<R> & {
		getCustomRowClassName?: (row: GridRowClassNameParams<R>) => string
		getSelectedRows?: (rows: R[]) => void
		onContextMenu?: ContextMenuHandler<R>
		onRowFocus?: (row: R | null) => void
		closeContextMenuOnSelect?: boolean
		renderContextMenuOptions?: (data: R, setState: () => void) => ReactNode[]
		enableContextMenu?: boolean
		contextMenuOptions?: ReactNode[]
	},
) {
	const [contextMenuState, setContextMenuState] = useState<{
		targetData: R | null
		contextMenu: {
			isOpen: boolean
			position: { mouseX: number; mouseY: number } | null
		}
	}>({ targetData: null, contextMenu: { isOpen: false, position: null } })
	const {
		closeContextMenuOnSelect = true,
		enableContextMenu = false,
		getCustomRowClassName,
		getSelectedRows,
		initialState,
		onContextMenu,
		onRowFocus,
		renderContextMenuOptions,
		rows,
		slotProps = {},
		slots,
		...rest
	} = props
	const rowsById = useMemo(() => new Map(rows?.map((row) => [row.id, row])), [rows])
	const openContextMenu = useRowContextMenu<R>((_, data, contextMenuPosition) =>
		setContextMenuState({ targetData: data, contextMenu: { isOpen: true, position: contextMenuPosition } }),
	)
	const closeContextMenu = () =>
		setContextMenuState({ contextMenu: { isOpen: false, position: null }, targetData: null })

	return (
		<>
			<StyledTable
				pageSizeOptions={DEFAULT_PAGE_OPTIONS}
				pagination
				showCellVerticalBorder
				showColumnVerticalBorder
				rowCount={rows?.length ?? 0}
				paginationMode="server"
				filterMode="server"
				columnHeaderHeight={TABLE_DEFAULT_ROW_HEADER_HEIGHT}
				rowHeight={TABLE_DEFAULT_ROW_HEIGHT}
				rows={rows}
				sortingMode="server"
				getRowClassName={(row) => {
					if (contextMenuState.contextMenu.isOpen)
						return row.id === contextMenuState.targetData?.id ? "context-menu-active" : ""
					return getCustomRowClassName ? getCustomRowClassName(row) : ""
				}}
				{...(getSelectedRows && rows
					? {
							onRowSelectionModelChange: (ids) =>
								getSelectedRows(ids.map((id) => rowsById.get(id as string)).filter((row): row is R => row !== undefined)),
						}
					: {})}
				initialState={{
					density: "compact",
					...initialState,
				}}
				slots={{
					rowReorderIcon: DragHandleIcon,
					noRowsOverlay: TableNoRowsOverlay,
					noResultsOverlay: TableNoRowsOverlay,
					loadingOverlay: TableLoaderOverlay,
					...slots,
				}}
				slotProps={{
					loadingOverlay: { pending: props.pending, variant: "linear-progress" },
					...slotProps,
					row: {
						...(Array.isArray(rows) && enableContextMenu
							? { onContextMenu: openContextMenu(rows), style: { cursor: "context-menu" } }
							: {}),
						...(onRowFocus
							? {
									onFocus: (e) => {
										const id = e.currentTarget.getAttribute("data-id") as string
										onRowFocus(rowsById.get(id) as R)
									},
									onBlur: () => onRowFocus(null),
								}
							: {}),
					},
				}}
				{...rest}
			/>
			{contextMenuState.targetData && enableContextMenu && (
				<Menu
					disableScrollLock
					open={contextMenuState.contextMenu.isOpen}
					onClick={() => {
						if (closeContextMenuOnSelect) closeContextMenu()
					}}
					onClose={() => {
						setContextMenuState((prevState) => ({
							...prevState,
							contextMenu: {
								...prevState.contextMenu,
								isOpen: false,
							},
						}))
					}}
					anchorReference="anchorPosition"
					{...(contextMenuState.contextMenu.position
						? {
								anchorPosition: {
									top: contextMenuState.contextMenu.position.mouseY,
									left: contextMenuState.contextMenu.position.mouseX,
								},
							}
						: {})}
				>
					{renderContextMenuOptions?.(contextMenuState.targetData, closeContextMenu)}
				</Menu>
			)}
		</>
	)
}

const GridOverlay = styled("div")(() => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	height: "100%",
	width: "100%",
}))

const TableNoRowsOverlay = React.memo(function TableNoRowsOverlay() {
	return (
		<GridOverlay>
			<AppsOutageIcon fontSize="large" />
			<Box className="no-results" sx={{ mt: 1, maxWidth: "20rem", textAlign: "center" }}>
				<Box>No results to display</Box>
				(according to current filters and available data)
			</Box>
		</GridOverlay>
	)
})

const TableLoaderOverlay = React.memo(function TableLoaderOverlay({
	pending = false,
	variant = "circular-progress",
}: GridLoadingOverlayProps) {
	const isLinear = variant === "linear-progress"
	const Comp = isLinear ? LinearProgress : variant === "circular-progress" ? CircularProgress : Skeleton
	return (
		<GridOverlay sx={{ justifyContent: isLinear ? "flex-start" : undefined }}>
			<Comp
				className="loader"
				{...(pending ? { color: "secondary" } : {})}
				sx={{ width: variant === "circular-progress" ? undefined : "100%" }}
			/>
		</GridOverlay>
	)
})
